// USA
export const locale = {
  TRANSLATOR: {
    SELECT: "Select your language",
  },
  MENU: {
    NEW: "New",
    ACTIONS: "Actions",
    CREATE_POST: "Create new post",
    PAGE_ADMINISTARATION: "Pages administration",
    PAGES: "Pages",
    PAGE: "Page",
    FEATURES: "Features",
    APPS: "Apps",
    DASHBOARD: "Dashboard",
    MEDIA_LIBRARY: "Media library",
    BLOG_CATEGORIES: "Blog Categories",
    BLOG_CATEGORY: "Blog category",
    BLOGS: "Blogs",
    POSTS: "Posts",
    POST: "Post",
    QUOTATIONS: "Testimonial",
    QUOTATION: "Testimonial administration",
    QUOTATION_CATEGORY: "Testimonial category",
    QUOTATION_CATEGORIES: "Testimonial categories",
    LOGO: "Logo",
    LOGOS: "Logos",
    COLLEAGUE: "Colleague",
    COLLEAGUES: "Colleagues administration",
    COLLEAGUES_ADMINISTRATION: "Colleagues administration",
    COLLEAGUE_CATEGORY: "Colleague category",
    COLLEAGUE_CATEGORIES: "Colleague categories",
    COOKIE_CATEGORIES: "Cookie categories",
    COOKIE_CATEGORY: "Cookie category",
    SETTINGS: "Settings",
    PAGE_TEMPLATE: "Page template",
    PAGE_TEMPLATES: "Page templates",
    TAG: "Tag",
    TAGS: "Tags",
    PAGE_SETTINGS: "Webpage settings",
    COOKIES: "Cookies",
    COOKIE: "Cookie",
    MENUS: "Menu administration",
    BANNERS: "Banner administration",
    PUBLICATIONS: "Publications",
    FAQ: "FAQ",
    CAREER: "Career",
    POSITIONS: "Positions",
    PRODUCTS: "Products",
    PROPERTIES: "Properties",
    CATEGORIES: "Categories",
    CATEGORY: "Category",

    systemSettings: "System settings",
    users: "Users",
    userGroups: "User groups",
    roles: "Roles",
    currencies: "Currencies",
    countries: "Countries",
    companySettings: "Company settings",
    departments: "Departments",
    sites: "Websites",
    banners: "Banners",
    banner: "Banner",
    contact_form: "Incoming forms",
    quotations: "Testimonials",
    quotation: "Testimonial",
    bannerTypes: "Banner types",
    bannerType: "Banner type",
    GALLERIES: "Galleries",
    GALLERY: "Gallery",
    year: "Year",
  },
  ALERT: {
    choose_only_maxFiles: "You can choose only {maxFiles} files!",
    file_info_title: "File information",
    created_at: "Created at",
    name: "Name",
    mime_type: "Mime type",
    path: "Path",
    size: "Size",
    width: "Width",
    height: "Height",
    pixel: "Pixel",
  },
  CONTACT_FORM: {
    contact_info: "Details",
    company_name: "Company name",
    created_at: "Created at",
    first_name: "First name",
    last_name: "Last name",
    locale: "Locale",
    message: "Message",
    phone: "Phone",
    remote_ip: "Remote IP",
    user_agent: "User agent",
    email: "E-mail",

    contact_form_id: "Contact form ID",
    zip: "ZIP code",
    city: "City",
    street: "Street",
    country: "Country",
    referer: "Referer",
    currency: "Currency",
    paymentId: "Payment ID",
    company_id: "Company ID",
    package_id: "Package ID",
    vat_number: "VAT number",
    name: "Name",
    position_id: "Position ID",
    position_name: "Position name",
  },
  AUTH: {
    WELCOME: "Welcome to SYNERGY fox",
    GENERAL: {
      OR: "Or",
      SUBMIT_BUTTON: "Submit",
      NO_ACCOUNT: "Don't you have an account yet?",
      SIGNUP_BUTTON: "Sign up",
      FORGOT_BUTTON: "Forgot your password?",
      BACK_BUTTON: "Back",
      PRIVACY: "Privacy",
      LEGAL: "Legal",
      CONTACT: "Contact",
    },
    LOGIN: {
      TITLE: "Login account",
      BUTTON: "Sign in",
      SIGN_IN: "Sign in",
      TEXT: "Enter your email address and password",
    },
    FORGOT: {
      TITLE: "Forgot your password?",
      DESC: "Enter your email to reset your password",
      SUCCESS: "Your account has been successfully reset.",
    },
    REGISTER: {
      TITLE: "Sign up",
      DESC: "Enter your data to create your account",
      SUCCESS: "Your account has been successfully registered.",
    },
    INPUT: {
      EMAIL: "Email",
      FULLNAME: "Name",
      PASSWORD: "Password",
      CONFIRM_PASSWORD: "Confirm password",
      USERNAME: "User name",
    },
    VALIDATION: {
      INVALID: "{{name}} is not valid",
      REQUIRED: "{{name}} is required",
      MIN_LENGTH: "The minimum length of {{name}} is {{min}} characters",
      AGREEMENT_REQUIRED: "Accepting terms & conditions are required",
      NOT_FOUND: "The requested {{name}} is not found",
      INVALID_LOGIN: "The login data is incorrect",
      REQUIRED_FIELD: "Required field",
      MIN_LENGTH_FIELD: "Minimum field length:",
      MAX_LENGTH_FIELD: "Maximum field length:",
      INVALID_FIELD: "Field is not valid",
    },
    USER_PROFILE: "User profile",
    SIGN_OUT_BUTTON: "Logout",
    HI: "Hi",
  },
  ECOMMERCE: {
    COMMON: {
      SELECTED_RECORDS_COUNT: "Selected records count: ",
      ALL: "All",
      SUSPENDED: "Suspended",
      ACTIVE: "Active",
      FILTER: "Filter",
      BY_STATUS: "by status",
      BY_TYPE: "by type",
      BUSINESS: "Business",
      INDIVIDUAL: "Individual",
      SEARCH: "Search",
      IN_ALL_FIELDS: "in all fields",
    },
    ECOMMERCE: "eCommerce",
    CUSTOMERS: {
      CUSTOMERS: "Customers",
      CUSTOMERS_LIST: "Customers list",
      NEW_CUSTOMER: "New Customer",
      DELETE_CUSTOMER_SIMPLE: {
        TITLE: "Customer Delete",
        DESCRIPTION: "Are you sure to permanently delete this customer?",
        WAIT_DESCRIPTION: "Customer is deleting...",
        MESSAGE: "Customer has been deleted",
      },
      DELETE_CUSTOMER_MULTY: {
        TITLE: "Customers Delete",
        DESCRIPTION: "Are you sure to permanently delete selected customers?",
        WAIT_DESCRIPTION: "Customers are deleting...",
        MESSAGE: "Selected customers have been deleted",
      },
      UPDATE_STATUS: {
        TITLE: "Status has been updated for selected customers",
        MESSAGE: "Selected customers status have successfully been updated",
      },
      EDIT: {
        UPDATE_MESSAGE: "Customer has been updated",
        ADD_MESSAGE: "Customer has been created",
      },
    },
  },

  // FORM_INPUT_NAMES: {
  //   total_net: "Total net",
  //   total_gross: "Total gross",
  //   owner_id: "Owner",
  //   paid: "Paid",
  //   lost: "Lost",
  //   source_type_id: "Source type",
  //   locale: "Locale",
  //   customer: "Customer",
  //   billingAddress: "Billing address",
  //   shippingAddress: "Shipping address",
  //   expiration_date: "Expiration date",
  //   delivery_date: "Delivery date",
  //   delivery_days: "Delivery days",
  //   price_list_id: "Price list",
  //   discount_value: "Discount value",
  //   discountNetValue: "Discount net value",
  //   notes: "Comment",
  //   status: "Status",
  //   sender_name: "Sender name",
  //   sender_email: "Sender email",
  //   languages: "Languages",
  //   select_logo: "Select logo",
  //   select_icon: "Válassz ikont",
  //   select_favicon: "Select favicon",
  //   select_image: "Select image",
  //   select_languages: "Select languages",
  //   select_public_languages: "Select public languages",
  //   alt_text: "Alt text",
  //   title: "Title",
  //   description: "Description",
  //   button_text: "Button text",
  //   text1: "Headline",
  //   text2: "Subheadline",
  //   name: "Name",
  //   type: "Type",
  //   desktop_image: "Desktop size image",
  //   mobile_image: "Mobile size image",
  //   // gallery: "Gallery",
  //   tags: "Tags",
  //   featured_image: "Featured image",
  //   card_image: "Card image",
  //   url: "URL",
  //   youtube_url: "YouTube URL",
  //   gtm: "GTM",
  //   recaptcha_site_key: "reCAPTCHA site key",
  //   recaptcha_site_secret: "reCAPTCHA site secret",
  //   placement: "Placement",
  //   select_range: "Select range",
  //   category: "Category",
  //   og_site_name: "OG site name",
  //   og_title: "OG site title",
  //   og_type: "OG type",
  //   og_url: "OG URL",
  //   og_description: "OG description",
  //   og_image: "OG image",
  //   url_prefix: "URL prefix",
  //   icon: "Icon",
  //   gallery: "Gallery",
  //   site_title: "Institute name",
  //   select_publication_item: "Select publication item",
  //   gMapURL: "Google map URL",
  //   question: "Question",
  //   answer: "Answer",
  //   select_background_image: "Select background image",
  //   select_service_image: "Select service image",
  //   select_usp_image: "Select USP images(svg)",
  //   select_module_image: "Select module images",
  //   hero_button_text: "Hero button text",
  //   hero_button_url: "Hero button URL",
  //   text: "Text",
  //   main_page: "Main page",
  //   company_name: "Name",
  //   company_tax_number: "Tax number",
  //   company_registration_number: "Registration number",
  //   company_bank_account_number: "Bank account number",
  //   company_address: "Address",
  //   company_phone: "Phone",
  //   company_fax: "Fax",
  //   company_email: "Email",
  //   company_gmap: "Google map URL",
  //   company_slogen: "History",
  //   sub_title: "Sub title",
  //   select_numbers: "Select numbers",
  //   select_hero_image: "Select hero image",
  //   select_solutions_image: "Select solution image",
  // },
  EMAIL: {
    offer: "Offer",
  },

  // FORMS: {
  //   new: "Új",
  //   menu: "Menü",
  //   edit_menu: "Menü szerkesztése",
  //   menus: "Menü szerkesztő",
  //   cancel: "Elvetés",
  //   save: "Mentés",
  //   status: "Státusz",
  //   name: "Név",
  //   label: "Cím",
  //   parent: "Szülő",
  //   icon: "Ikon",
  //   target: "Cél",
  // },

  PERMISSIONS: {
    module_companySettings_menu: "Company setting modul",
    module_pages_menu: "Pages modul",
    module_contactForms_menu: "Contact forms modul",
    module_quotations_menu: "Testimonials modul",
    module_blog_menu: "Blog modul",
    module_logos_menu: "Logo modul",
    module_colleagues_menu: "Colleagues modul",
    module_gdpr_menu: "Settings modul",
    module_galleries_menu: "Galleries modul",
    module_news_menu: "News modul",
    module_external_references_menu: "External references modul",
    module_faq_menu: "Faq modul",
    module_career_menu: "Career modul",
    module_packages_menu: "Packages modul",
    module_products_menu: "Products modul",
    module_webshop_menu: "Webshop modul",

    system_setting_menu: "System settings - System settings menu",
    system_setting_update: "System settings - Update system settings",
    system_setting_view: "System settings - View system settings",
    system_user_menu: "System settings - Users menu",
    system_user_create: "System settings - Create user",
    system_user_delete: "System settings - Delete user",
    system_user_update: "System settings - Update user",
    system_user_update_self: "System settings - Update user self",
    system_user_view: "System settings - View user",
    system_user_view_self: "System settings - View user self",
    system_userGroup_menu: "System settings - User groups menu",
    system_userGroup_create: "System settings - Create user group",
    system_userGroup_delete: "System settings - Delete user group",
    system_userGroup_update: "System settings - Update user group",
    system_userGroup_update_self: "System settings - Update user group self",
    system_userGroup_view: "System settings - View user group",
    system_userGroup_view_self: "System settings - View user group self",
    system_role_menu: "System settings - Roles menu",
    system_role_create: "System settings - Create role",
    system_role_delete: "System settings - Delete role",
    system_role_update: "System settings - Update role",
    system_role_view: "System settings - View role",
    system_country_menu: "System settings - Countries menu",
    system_country_update: "System settings - Update country",
    system_country_view: "System settings - View country",
    system_currency_menu: "System settings - Currencies menu",
    system_currency_create: "System settings - Create currency ",
    system_currency_delete: "System settings - Delete currency",
    system_currency_update: "System settings - Update currency",
    system_currency_view: "System settings - View currency",

    companySettings_department_menu: "Company settings - Departments menu",
    companySettings_department_create: "Company settings - Create department",
    companySettings_department_delete: "Company settings - Delete department",
    companySettings_department_update: "Company settings - Update department",
    companySettings_department_view: "Company settings - View department",
    companySettings_site_menu: "Company settings - Sites menu",
    companySettings_site_create: "Company settings - Create site",
    companySettings_site_delete: "Company settings - Delete site",
    companySettings_site_update: "Company settings - Update site",
    companySettings_site_view: "Company settings - View site",

    pages_page_create: "Pages - Create page",
    pages_page_delete: "Pages - Delete page",
    pages_page_view: "Pages - View page",
    pages_page_update: "Pages - Update page",
    pages_page_menu: "Pages - Pages menu",

    blog_tag_create: "Blog - Create tag",
    blog_tag_delete: "Blog - Delete tag",
    blog_tag_view: "Blog - View tag",
    blog_tag_update: "Blog - Update tag",
    blog_tag_menu: "Blog - Tags menu",

    pages_menu_create: "Pages - Create menu",
    pages_menu_delete: "Pages - Delete menu",
    pages_menu_view: "Pages - View menu",
    pages_menu_update: "Pages - Update menu",
    pages_menu_menu: "Pages - Menus menu",

    pages_banner_create: "Pages - Create banner",
    pages_banner_delete: "Pages - Delete banner",
    pages_banner_view: "Pages - View banner",
    pages_banner_update: "Pages - Update banner",
    pages_banner_menu: "Pages - Banners menu",

    contactForms_contactForm_create: "Contact forms - Create contact form",
    contactForms_contactForm_delete: "Contact forms - Delete contact form",
    contactForms_contactForm_view: "Contact forms - View contact form",
    contactForms_contactForm_update: "Contact forms - Update contact form",
    contactForms_contactForm_menu: "Contact forms - Contact forms menu",

    quotations_quotation_create: "Testimonials - Create testimonial",
    quotations_quotation_delete: "Testimonials - Delete testimonial",
    quotations_quotation_view: "Testimonials - View testimonial",
    quotations_quotation_update: "Testimonials - Update testimonial",
    quotations_quotation_menu: "Testimonials - Testimonials menu",

    blog_category_create: "Blog - Create category",
    blog_category_delete: "Blog - Delete category",
    blog_category_view: "Blog - View category",
    blog_category_update: "Blog - Update category",
    blog_category_menu: "Blog - Categories menu",

    faq_category_create: "FAQ - Create category",
    faq_category_delete: "FAQ - Delete category",
    faq_category_view: "FAQ - View category",
    faq_category_update: "FAQ - Update category",
    faq_category_menu: "FAQ - Categories menu",

    blog_post_create: "Blog - Create post",
    blog_post_delete: "Blog - Delete post",
    blog_post_view: "Blog - View post",
    blog_post_update: "Blog - Update post",
    blog_post_menu: "Blog - Posts menu",

    gdpr_code_create: "Settings - Create GDPR",
    gdpr_code_delete: "Settings - Delete GDPR",
    gdpr_code_view: "Settings - View GDPR",
    gdpr_code_update: "Settings - Update GDPR",
    gdpr_code_menu: "Settings - GDPR menu",

    gdpr_category_create: "Settings - Create GDPR category",
    gdpr_category_delete: "Settings - Delete GDPR category",
    gdpr_category_view: "Settings - View GDPR category",
    gdpr_category_update: "Settings - Update GDPR category",
    gdpr_category_menu: "Settings - GDPR categories menu",

    logos_category_create: "Logos - Create logo category",
    logos_category_delete: "Logos - Delete logo category",
    logos_category_view: "Logos - View logo category",
    logos_category_update: "Logos - Update logo category",
    logos_category_menu: "Logos - Logo categories menu",

    colleagues_colleague_create: "Colleagues - Create colleague",
    colleagues_colleague_delete: "Colleagues - Delete colleague",
    colleagues_colleague_view: "Colleagues - View colleague",
    colleagues_colleague_update: "Colleagues - Update colleague",
    colleagues_colleague_menu: "Colleagues - Colleagues menu",

    colleagues_colleagueCategory_create:
      "Colleagues - Create colleague category",
    colleagues_colleagueCategory_delete:
      "Colleagues - Delete colleague category",
    colleagues_colleagueCategory_view: "Colleagues - View colleague category",
    colleagues_colleagueCategory_update:
      "Colleagues - Update colleague category",
    colleagues_colleagueCategory_menu: "Colleagues - Colleagues category menu",

    pages_pageTemplate_create: "Pages - Create page template",
    pages_pageTemplate_delete: "Pages - Delete page template",
    pages_pageTemplate_view: "Pages - View page template",
    pages_pageTemplate_update: "Pages - Update page template",
    pages_pageTemplate_menu: "Pages - Page templates menu",

    pages_bannerType_create: "Pages - Create banner type",
    pages_bannerType_delete: "Pages - Delete banner type",
    pages_bannerType_view: "Pages - View banner type",
    pages_bannerType_update: "Pages - Update banner type",
    pages_bannerType_menu: "Pages - Banner types menu",

    quotations_quotationCategory_create:
      "Testimonials - Create testimonial category",
    quotations_quotationCategory_delete:
      "Testimonials - Delete testimonial category",
    quotations_quotationCategory_view:
      "Testimonials - View testimonial category",
    quotations_quotationCategory_update:
      "Testimonials - Update testimonial category",
    quotations_quotationCategory_menu:
      "Testimonials - Testimonial categories menu",

    blog_setting_create: "Blog - Create setting",
    blog_setting_delete: "Blog - Delete setting",
    blog_setting_view: "Blog - View setting",
    blog_setting_update: "Blog - Update setting",
    blog_setting_menu: "Blog - Settings menu",

    colleagues_setting_create: "Colleagues - Create setting",
    colleagues_setting_delete: "Colleagues - Delete setting",
    colleagues_setting_view: "Colleagues - View setting",
    colleagues_setting_update: "Colleagues - Update setting",
    colleagues_setting_menu: "Colleagues - Settings menu",

    faq: "FAQ",
    faq_setting_create: "FAQ - Create setting",
    faq_setting_delete: "FAQ - Delete setting",
    faq_setting_view: "FAQ - View setting",
    faq_setting_update: "FAQ - Update setting",
    faq_setting_menu: "FAQ - Settings menu",

    news_category_create: "News - Create category",
    news_category_delete: "News - Delete category",
    news_category_view: "News - View category",
    news_category_update: "News - Update category",
    news_category_menu: "News - Categories menu",

    news_news_create: "News - Create news",
    news_news_delete: "News - Delete news",
    news_news_view: "News - View news",
    news_news_update: "News - Update news",
    news_news_menu: "News - News menu",

    news_tag_create: "News - Create tag",
    news_tag_delete: "News - Delete tag",
    news_tag_view: "News - View tag",
    news_tag_update: "News - Update tag",
    news_tag_menu: "News - Tags menu",

    news_setting_create: "News - Create setting",
    news_setting_delete: "News - Delete setting",
    news_setting_view: "News - View setting",
    news_setting_update: "News - Update setting",
    news_setting_menu: "News - Settings menu",

    galleries_category_create: "Galleries - Create gallery",
    galleries_category_delete: "Galleries - Delete gallery",
    galleries_category_view: "Galleries - View gallery",
    galleries_category_update: "Galleries - Update gallery",
    galleries_category_menu: "Galleries - Galleries menu",

    galleries_setting_create: "Galleries - Create setting",
    galleries_setting_delete: "Galleries - Delete setting",
    galleries_setting_view: "Galleries - View setting",
    galleries_setting_update: "Galleries - Update setting",
    galleries_setting_menu: "Galleries - Settings menu",

    external_references_category_create:
      "External references - Create external reference",
    external_references_category_delete:
      "External references - Delete external reference",
    external_references_category_view:
      "External references - View external reference",
    external_references_category_update:
      "External references - Update external reference",
    external_references_category_menu:
      "External references - External references menu",

    system_site_view: "Settings - View website settings",
    system_site_update: "Settings - Update website settings",
    system_site_menu: "Settings - Website settings menu",
    system_mediaLibrary_menu: "Media library menu",

    system: "System settings",
    companySettings: "Company settings",
    module: "Modules",
    pages: "Pages",
    contactForms: "Contact forms",
    quotations: "Testimonials",
    blog: "Blog",
    logos: "Logos",
    colleagues: "Colleagues",
    gdpr: "Settings",
    galleries: "Galleries",

    external_references_setting_create:
      "External references - Create external reference setting",
    external_references_setting_delete:
      "External references - Delete external reference setting",
    external_references_setting_view:
      "External references - View external reference setting",
    external_references_setting_update:
      "External references - Update external reference setting",
    external_references_setting_menu:
      "External references - External references settings menu",

    career_career_create: "Career - Create position",
    career_career_delete: "Career - Delete position",
    career_career_view: "Career - View position",
    career_career_update: "Career - Update position",
    career_career_menu: "Career - Positions menu",

    career_setting_create: "Career - Create setting",
    career_setting_delete: "Career - Delete setting",
    career_setting_view: "Career - View setting",
    career_setting_update: "Career - Update setting",
    career_setting_menu: "Career - Settings menu",

    packages: "Packages",
    packages_package_create: "Packages - Create package",
    packages_package_delete: "Packages - Delete package",
    packages_package_view: "Packages - View package",
    packages_package_update: "Packages - Update package",
    packages_package_menu: "Packages - Packages menu",

    packages_setting_create: "Packages - Create setting",
    packages_setting_delete: "Packages - Delete setting",
    packages_setting_view: "Packages - View setting",
    packages_setting_update: "Packages - Update setting",
    packages_setting_menu: "Packages - Settings menu",

    products: "Products",
    products_property_create: "Products - Create property",
    products_property_delete: "Products - Delete property",
    products_property_view: "Products - View property",
    products_property_update: "Products - Update property",
    products_property_menu: "Products - Property menu",

    products_category_create: "Products - Create category",
    products_category_delete: "Products - Delete category",
    products_category_view: "Products - View category",
    products_category_update: "Products - Update category",
    products_category_menu: "Products - Category menu",

    products_product_create: "Products - Create product",
    products_product_delete: "Products - Delete product",
    products_product_view: "Products - View product",
    products_product_update: "Products - Update product",
    products_product_menu: "Products - Product menu",

    products_price_list_create: "Products - Create price list",
    products_price_list_delete: "Products - Delete price list",
    products_price_list_view: "Products - View price list",
    products_price_list_update: "Products - Price list product",
    products_price_list_menu: "Products - Price list menu",

    products_setting_create: "Products - Create setting",
    products_setting_delete: "Products - Delete setting",
    products_setting_view: "Products - View setting",
    products_setting_update: "Products - Update setting",
    products_setting_menu: "Products - Settings menu",

    webshop: "Webshop",
    webshop_coupon_create: "Webshop - Create coupon",
    webshop_coupon_delete: "Webshop - Delete coupon",
    webshop_coupon_view: "Webshop - View coupon",
    webshop_coupon_update: "Webshop - Update coupon",
    webshop_coupon_menu: "Webshop - Coupon menü",

    webshop_order_create: "Webshop - Create order",
    webshop_order_delete: "Webshop - Delete order",
    webshop_order_view: "Webshop - View order",
    webshop_order_update: "Webshop - Update order",
    webshop_order_menu: "Webshop - order menü",

    webshop_setting_create: "Webshop - Create setting",
    webshop_setting_delete: "Webshop - Delete setting",
    webshop_setting_view: "Webshop - View setting",
    webshop_setting_update: "Webshop - Update settings",
    webshop_setting_menu: "Webshop - Settings menu",
  },

  FORMS: {
    new: "New",
    news: "News",
    menu: "Menu",
    edit_menu: "Menu administration",
    menus: "Menus administration",
    cancel: "Cancel",
    save: "Save",
    status: "Status",
    name: "Name",
    label: "Label",
    parent: "Parent",
    icon: "Icon",
    target: "Target",
    slug: "Slug",
    active: "Active",
    inactive: "Inactive",
    are_you: "Are you sure you want to delete this item",
    edit: "Edit",
    pageName: "Page name",
    excel_export: "Export xls",
    excel_exporting: "Exporting to xls file",
    new_role: "New role",
    shared_files: "Root folder",
    countries: "Countries",
    upload: "Upload",
    date_of_created_at: "Date of created at",
    time_of_created_at: "Time of created at",
    highlighted_text: "Highlighted text",
    requirements: "Requirements",

    search: "Search",
    user: "User",
    users: "Users",
    userGroup: "User group",
    userGroups: "User groups",
    roles: "Roles",
    permissions: "Permissions",
    active_countries: "Active countries",
    sites: "Sites",
    choose_site: "Choose site",

    firstName: "First name",
    middleName: "Middle name",
    lastName: "Last name",
    language: "Language",
    password: "Password",
    userGroupName: "Select user group",
    select: "Select",
    contact_form: "Contact forms",
    close: "Close",
    companyName: "Company name",
    text: "Text",
    intro: "Intro text",
    position: "Position",
    email: "E-mail",
    phone: "Phone",
    seo_title: "SEO title",
    seo_description: "SEO description",
    category: "Category",
    select_users: "Select Users",
    newsletter: "Newsletter",
    // choose_site: "Choose site",
    fields: "Fields",
    short_description: "Short description",
    description: "Description",
    system_settings: "System settings",

    new_currency: "New currency",
    currencies: "Currencies",
    actions: "Actions",
    select_site_language_order: "Select site languages",
    content_language: "Language of the content",
    system_language: "System language",
    add: "Add",
    block1: "Block 1",
    block2: "Block 2",
    block3: "Block 3",
    block4: "Block 4",
    block5: "Block 5",
    block6: "Block 6",
    quotation_block: "Quotation block",
    usp: "USP",
    modules: "Modules",
    contact_block: "Contact block",
    company_settings: "Company settingd",
    title: "Title",
    hero_title: "Hero title",
    hero_text: "Hero text",
    hero_button_text: "Hero button text",
    hero_button_url: "Hero button URL",
    button_url: "Button URL",
    solutions_title: "Solutions title",
    solution1_title: "Solution 1 title",
    solution1_text: "Solution 1 text",
    solution1_url: "Solution 1 url",
    solution2_title: "Solution 2 title",
    solution2_text: "Solution 2 text",
    solution2_url: "Solution 2 url",
    solution3_title: "Solution 3 title",
    solution3_text: "Solution 3 text",
    solution3_url: "Solution 3 url",
    solution4_title: "Solution 4 title",
    solution4_text: "Solution 4 text",
    solution4_url: "Solution 4 url",
    quotes_title: "Quotations title",
    quotes_text: "Quotations text",
    tryout_text: "Try out text",
    tryout_button_text: "Try out button text",
    tryout_button_url: "Try out button url",
    timeLine: "Timeline",
    colleagues: "Colleagues",
    surname: "Surname",
    webshop: "Webshop",
    blog: "Blog",
    ac_contact: "AC contact details",
  },

  FORM_INPUT_NAMES: {
    parent_category: "Parent category",
    total_net: "Total net",
    total_gross: "Total gross",
    total_net_special: "Total net special",
    total_gross_special: "Total gross special",
    owner_id: "Owner",
    paid: "Paid",
    lost: "Lost",
    source_type_id: "Source type",
    locale: "Locale",
    customer: "Customer",
    billingAddress: "Billing address",
    shippingAddress: "Shipping address",
    expiration_date: "Expiration date",
    delivery_date: "Delivery date",
    delivery_days: "Delivery days",
    price_list_id: "Price list",
    discount_value: "Discount value",
    discountNetValue: "Discount net value",
    notes: "Comment",
    status: "Status",
    sender_name: "Sender name",
    sender_email: "Sender email",
    languages: "Languages",
    select_logo: "Select logo",
    select_icon: "Válassz ikont",
    select_favicon: "Select favicon",
    select_image: "Select image",
    select_languages: "Select languages",
    select_public_languages: "Select public languages",
    alt_text: "Alt text",
    title: "Title",
    title2: "Title 2nd line",
    description: "Description",
    button_text: "Button text",
    text1: "Headline",
    text2: "Subheadline",
    name: "Name",
    surname: "Vezetéknév",
    message: "Üzenet",
    type: "Type",
    desktop_image: "Desktop size image",
    mobile_image: "Mobile size image",
    // gallery: "Gallery",
    tags: "Tags",
    featured_image: "Featured image",
    card_image: "Card image",
    url: "URL",
    resellerLicence: "Reseller licence",
    youtube_url: "YouTube URL",
    gtm: "GTM",
    recaptcha_site_key: "reCAPTCHA site key",
    recaptcha_site_secret: "reCAPTCHA site secret",
    placement: "Placement",
    select_range: "Select range",
    category: "Category",
    og_site_name: "OG site name",
    og_title: "OG site title",
    og_type: "OG type",
    og_url: "OG URL",
    og_description: "OG description",
    og_image: "OG image",
    url_prefix: "URL prefix",
    icon: "Icon",
    gallery: "Gallery",
    site_title: "Institute name",
    select_publication_item: "Select publication item",
    gMapURL: "Google map URL",
    question: "Question",
    answer: "Answer",
    select_background_image: "Select background image",
    select_service_image: "Select service image",
    select_usp_image: "Select USP images(svg)",
    select_module_image: "Select module images",
    hero_button_text: "Hero button text",
    hero_button_url: "Hero button URL",
    text: "Text",
    main_page: "Main page",
    company_name: "Name",
    company_tax_number: "Tax number",
    company_registration_number: "Registration number",
    company_bank_account_number: "Bank account number",
    company_address: "Address",
    company_phone: "Phone",
    company_fax: "Fax",
    company_email: "Email",
    company_gmap: "Google map URL",
    company_slogen: "History",
    sub_title: "Sub title",
    select_numbers: "Select numbers",
    select_hero_image: "Select hero image",
    select_solutions_image: "Select solution image",
    block1: "1. block",
    block2: "2. block",
    block3: "3. block",
    block4: "4. block",
    block5: "5. block",
    block6: "6. block",
    block7: "7. block",
    block8: "8. block",
    block9: "9. block",
    block10: "10. block",
    iframe: "IFRAME",
    select_colleagues: "Select Colleagues",
    position: "Position",
    date: "Date",
    special_offer: "Special offer",
    recommend: "We recommend",
    shipping_quantity: "Shipping quantity",
    quantity_unit: "Mértékegység",
    product_offer: "Offered product",
    select_slogan_image: "Select Slogan Image",
    footer_offer: "Footer offer",
    select_youtube_image: "Select Youtube image",
    product_name: "Product name",
    shipping_price: "Shipping price",
    free_shipping_from_price: "Free shipping from given price",
    ordered_at: "Ordered at",
    isPackage: "Product type",
    sub_text1: "Sub text1",
    sub_text2: "Sub text2",
    webshop: "Webshop",
    blog: "Blog",
    banner: "Banner",
    select_banner: "Select banners",
    events: "Events",
    reseller_license: "Reseller license",
    image_resolution: "Image resolution",
    generated: "Generated",
    sale: "Sale",
  },

  ERRORS: {
    file_already_exists: "File already exists",
    field_is_required: "This field is required",
    must_be_max_characters: "This field must be max 250 characters",
    email_is_valid: "Email or password is invalid",
    password_is_required: "Password is required",
  },
  MEDIA_LIBRARY: {
    create_new_directory: "New directory",
    rename_directory: "Rename directory",
    rename_file: "Rename file",
    file_upload: "Upload file",
    please_select: "Please select",
    uploading: "Uploading",
    edit_image: "Edit image",
    crop_and_save: "Crop and save",
    file_upload_max_size: "Max upload file size",
    file_upload_max_count: "Upload file max count",
    select_media: "Select media",
  },

  TABLE: {
    created_at: "Created at",
    created_at_date: "Created at date",
    name: "Name",
    status: "Status",
    actions: "Actions",
    type: "Type",
    page: "Page",
    slug: "Slug",
    post: "Post",
    blog_category: "Blog category",
    created: "Created",
    phone: "Phone",
    company: "Company",
    quotationCategory: "Testimonial category",
    logo: "Logo",
    colleagueCategory: "Colleague category",
    id: "ID",
    country: "Country",
    language: "Language",
    currency: "Currency",
    eu: "EU",
    are_you_sure_delete: "Are you sure you want to delete?",
    sign: "Sign",
    exchange_rate: "Exchange rate",
    cannot_delete: "This item is not deletable",
    translated: "Translated",
    first_name: "First name",
    christian_name2: "Middle name",
    last_name: "Last name",
    ok: "Ok",
    cancel: "Cancel",
    yes: "Yes",
    no: "No",
    date: "Date",
    categories: "Categories",
    newsletter: "Newsletter",
    coupon: "Coupon",
  },

  STATUSES: {
    inactive: "Inactive",
    active: "Active",
  },

  COUPON_TYPES: {
    amount: "Összeg",
    percent: "Százalék",
    shipping: "Szállítás",
  },

  EXCELL: {
    created_at: "Created at",
    first_name: "First name",
    last_name: "Last name",
    email: "Email",
    phone: "Phone",
    company_name: "Company",
    message: "Message",
    locale: "Language",
    remote_ip: "Remote ip",
    user_agent: "User agent",
    name: "Name",
    contact_form_id: "Contact form ID",
  },

  SELECTS: {
    1: "Default",
    2: "URL",
    3: "Text",
    4: "PDF",
  },

  BANNERS: {
    BG_OF_BANNER_IS_IMAGE: "Banner background image",
    BG_OF_BANNER_IS_EMBEDDED_VIDEO: "Banner background video",
  },

  PRICE_LIST: {
    currency: "Currency",
    productName: "Product name",
    searchProduct: "Search product",
    priceListName: "Price list name",
    defaultTaxName: "VAT name", // Tax name
    defaultTaxRate: "VAT value",
    productsOnPriceList: "Price in pricelist", // List of items on this price list
    productPriceNet: "Product net price", // Product price net
    productPriceGross: "Product gross price", // Product price gross
  },

  COUPONS: {
    coupons: "Coupons",
    coupon: "Coupon",
    max_use_num: "Max use num",
    max_use_num_per_user: "Max use num per user",
    value: "Value",
  },
  WEBSHOP: {
    customers: "Customers",
    customer: "Customer",
    orders: "Orders",
    order: "Order",
    shipping_address: "Shipping address",
    billing_address: "Billing address",
  },
  ORDER_STATUSES: {
    0: "Abandoned",
    1: "New",
    2: "In progress",
    3: "Completed",
    4: "Deleted",
    5: "Waitin for packaging",
    6: "Packaging",
    7: "Shipping",
  },
  dataIterator: {
    rowsPerPageText: "Darabok:",
  },

  activity: {
    username: "Username",
  },
};
