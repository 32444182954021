const SiteService = {
  defaultSiteId: -1,

  getActiveSiteId() {
    if (!window.localStorage.getItem("active_site_id"))
      return this.defaultSiteId;

    return parseInt(window.localStorage.getItem("active_site_id"));
  },

  setActiveSiteId(site_id) {
    return new Promise((resolve) => {
      window.localStorage.setItem("active_site_id", site_id);
      resolve();
    });
  },
};
export default SiteService;
